<template>
  <div class="bg-black">
    <div class="max-w-7xl mx-auto py-16 min-h-screen ">
      <div class="pb-4 space-y-2 sm:px-6 sm:space-y-4 lg:px-8 text-gray-400 text-md font-mono">Deze changelog beschrijft de belangrijkste wijzigingen en bugfixes die zijn gedaan aan de portal.</div>
      
      <div role="list" class="py-4 space-y-2 sm:px-6 sm:space-y-4 lg:px-8 font-mono">
        <div class="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
          <div class="sm:flex sm:justify-between sm:items-baseline">
            <h3 class="text-base font-medium">
              <span class="text-gray-900">Rutger</span>
              {{ ' ' }}
              <span class="text-gray-600">schreef</span>
            </h3>
            <p class="mt-1 text-sm text-gray-600 whitespace-nowrap sm:mt-0 sm:ml-3">
              <time>9 september 2022</time>
            </p>
          </div>
          <div class="mt-4 space-y-6 text-sm text-gray-800">
            Authenticatie gemigreerd naar MSID / AD
          </div>
        </div>
        
        <div class="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
          <div class="sm:flex sm:justify-between sm:items-baseline">
            <h3 class="text-base font-medium">
              <span class="text-gray-900">Rutger</span>
              {{ ' ' }}
              <span class="text-gray-600">schreef</span>
            </h3>
            <p class="mt-1 text-sm text-gray-600 whitespace-nowrap sm:mt-0 sm:ml-3">
              <time>14 maart 2022</time>
            </p>
          </div>
          <div class="mt-4 space-y-6 text-sm text-gray-800">
            CV search indexing: (t.b.v. vinden cv's a.d.h.v. cv teksten) verbeterd. Nu is het nog efficienter cv's te zoeken voor Sales.
            <br/>
            Zoeken in CV's iets vriendelijker door gebruik 'enter' ook te laten zoeken.
            <br/>
            Bugfix skill overzicht die een 404 gaf.
          </div>
        </div>

        <div class="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
          <div class="sm:flex sm:justify-between sm:items-baseline">
            <h3 class="text-base font-medium">
              <span class="text-gray-900">Rutger</span>
              {{ ' ' }}
              <span class="text-gray-600">schreef</span>
            </h3>
            <p class="mt-1 text-sm text-gray-600 whitespace-nowrap sm:mt-0 sm:ml-3">
              <time>11 maart 2022</time>
            </p>
          </div>
          <div class="mt-4 space-y-6 text-sm text-gray-800">
            Login en 2 factor authenticatie nog vriendelijker gemaakt door te redirecten naar de pagina die initieel werd opgevraagd.
          </div>
        </div>

      </div>
    </div>
  </div>
</template>